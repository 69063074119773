<template>
  <div class="anggota">
    <h1 class="mb-5">Edit Anggota</h1>
    <Form :initial-anggota="anggota" v-if="anggota" :isEdit="true" />
    <h1 v-else>Loading...</h1>
  </div>
</template>

<script>
import Form from "./components/form/Form.vue";
import { anggotaService } from "@/services";

export default {
  components: {
    Form,
  },
  data() {
    return {
      // Data anggota
      anggota: "",
    };
  },
  methods: {
    // Specific select onchange events
    async loadData() {
      const resp = await anggotaService.getById(this.$route.params.id);
      this.anggota = resp.data;
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>

<style scoped>
.anggota {
  padding: 3rem 1.5rem;
}
</style>
